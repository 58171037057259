import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { ImageTowerComponent } from './image-tower/image-tower.component';
import { AboutContactComponent } from './about-contact/about-contact.component';
import { LinksPageComponent } from './links-page/links-page.component';
import { ArtDaySinglePageComponent } from './art-day-single-page/art-day-single-page.component';
import { TeachRedirectComponent } from './teach-redirect/teach-redirect.component';

const emptyList: any[] = [];

const routes: Routes = emptyList.concat([
  { path: '', pathMatch: 'full', redirectTo: '/DrawingsAndPaintings' },
  { path: 'about-contact', component: AboutContactComponent },
  { path: 'links-page', component: LinksPageComponent },
  { path: 'teach', component: TeachRedirectComponent },
  { path: ':collection', component: ImageTowerComponent },
  { path: 'Years/:collection', component: ImageTowerComponent },
  { path: 'Things/:filename', component: ImageTowerComponent },
  { path: 'Stories/:fileName', component: ArtDaySinglePageComponent },
  { path: 'Stories/More/:fileName', component: ArtDaySinglePageComponent },
  { path: 'ArtistsBooks/:collection', component: ImageTowerComponent },
  { path: '**', component: Page404Component }  // Wildcard route for a 404 page
]);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
