<div class="container">
  <div class="row">
    <div class="col col-8">
      <div class="image-container">
        <img src="/assets/icons/Michaela-Chan.png" alt="Photo of Michaela Chan"/>
        <div class="caption">Photograph by Nat Holtzmann</div>
      </div>
      <p>
        Michaela is observant 
        <br>(see the thing?) 
        <br>and iterative 
        <br>(again and again). 
        <br> She's a poet cartoonist
        <br>living in Rochester, NY.
        <br>
      
       <br>
        Past projects include LAB RAT 
        <br>(<a href="https://theoffingmag.com/art/comics/lab-rat/">1</a>,
        <a href="https://therumpus.net/2022/12/15/lab-rat/">2</a>, and 
        <a href="https://as.vanderbilt.edu/nashvillereview/archives/18541">3</a>)
        <br>and Stories (<a href="https://michaelachan.com/Stories/100_The-Giant_2023_self-published.pdf">The Giant,</a>
        <br> <a href="https://michaelachan.com/ArtistsBooks/010_Why-does-the-sun-set_2024">Why Does the Sun Set?,</a>
        <br> <a href="https://michaelachan.com/ArtistsBooks/006_Atrium-Garden_2023">Atrium Garden</a>)
        <br>
        <br>
      </p>
    </div>

    <div class="col col-4">
      <p style="float: right; padding: 10px; border: 3px solid black;"> Be in touch
        <br>email
        <br> "chan.michaela [at sign] g m a i l.com"
        <br> 
        <br>
      </p>
    </div>
  </div>

  <p style="text-align: center; font-size: x-large">
    See work by year
  </p>
  <div style="text-align: center;">
    <ul class="year-buttons">
      <li *ngFor="let year of years">
        <a [attr.href]="'Years/' + year">
          <button>{{ year }}</button>
        </a>
      </li>
    </ul>
  </div>


  <p>
    <i>We embroider memories onto lace.
      <br> As I mark, I court mistakes
      <br> to be fidelitous to that lace -
      <br> its frames or threadless spaces. </i>
    <br>
  </p>

  <img src="/assets/icons/headshot_michaela-chan.png" style='display:block; width:50%; margin: 0 auto;'/>
  <p>
    Theo Chapman set up this website and <a href="https://patrickphillips.page/">Patrick Phillips</a> is the
    codemaster! Many thanks!
  </p>
</div>
