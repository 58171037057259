import { ArtDaySinglePageComponent } from './art-day-single-page/art-day-single-page.component';

let empty_list: any[] = []

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArtDayBodyComponent } from './art-day-pieces/art-day-body.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutContactComponent } from './about-contact/about-contact.component';
import { ImageTowerComponent } from './image-tower/image-tower.component';
import { CollectionFooterComponent } from './art-day-pieces/collection-footer.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ArtDayWrapperComponent } from './art-day-wrapper/art-day-wrapper.component';
import { SafePipe } from './utils/utils';
import { HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { TeachRedirectComponent } from './teach-redirect/teach-redirect.component';

@NgModule({
  declarations: empty_list.concat([
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutContactComponent,
    TeachRedirectComponent,
    ImageTowerComponent,
    ArtDayBodyComponent,
    ArtDaySinglePageComponent,
    ArtDayWrapperComponent,
    CollectionFooterComponent,
    SafePipe,

  ]),
  imports: [
    InfiniteScrollModule,
    AppRoutingModule,
    HammerModule,
    BrowserAnimationsModule,
    BrowserModule,
    AccordionModule.forRoot(),
    AlertModule,
    ButtonsModule,
    FormsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule],

  providers: [AlertConfig, BsDatepickerConfig, BsDropdownConfig],
  bootstrap: [AppComponent]
})
export class AppModule { }
