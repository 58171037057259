import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teach-redirect',
  template: '', // Empty template since we're just redirecting
  styles: []
})
export class TeachRedirectComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    window.location.href = '/assets/teach.pdf';
  }
}