<div class="container">
  <div class="row">
    <div class="col col-8">
      <div class="image-container">
        <img src="/assets/icons/Michaela-Chan.png" alt="Photo of Michaela Chan"/>
        <div class="caption">Photograph by Nat Holtzmann</div>
      </div>
      <p>
        Michaela is observant 
        <br>(see the thing?) 
        <br>and iterative 
        <br>(again, again!). 
        <br>She makes books
        <br>(writes, draws, binds)
        <br>and teaches in her 
        <br>beloved Rochester, NY.
        <br>
      </p>
    </div>
   <div class="image-container">
      <img src="/assets/icons/Flyer_Book-work_MCHAN.jpg" alt="Flyer for Book Work"/>
  
    </div>  
    <div class="col col-4">
      <p style="float: right; padding: 10px; border: 3px solid black;"> Be in touch
        <br>email
        <br> "chan.michaela [at sign] g m a i l.com"
        <br> 
        <br>
      </p>
    </div>
  </div>    
    <p>
      <br> Book Work is a free event series hosted by 
      <br> Rathaus Press, Flower City Arts Center, 
      <br> and The Yards Collective. Drop in anytime 
      <br> to hear MC read new work and give away zines. 
      <br> Handmade books including sketchbooks and 
      <br> storybooks will be available for purchase. 
      <br> SYZYGY IN THE CITY, from Zaftig Press, will be stocked! 
      <br> This project is made possible in part with funds 
      <br> from the Genesee Valley Council on the Arts Statewide 
      <br> Community Regrant program.
    <br>  
    <br>
        <br>Q: Where to find more work?
        <br> A: <a href="https://zaftigpress.com/">Zaftig Press</a> published <i>Syzygy in the City</i> in December 2024
        <br>  <a href="https://paperbagazine.com/">Paperbag</a> put <i>A Handful of Ink Paintings</i> in the fall bagazine, 
        <br> and <a href="https://pulpliterature.com/back-issues-2/issue-43-summer-2024/">Pulp Literature</a> included <i>Fasteners</i> in Issue 43, Summer 2024!
       <br>
        Past projects include LAB RAT 
        <br>(<a href="https://theoffingmag.com/art/comics/lab-rat/">1</a>,
        <a href="https://therumpus.net/2022/12/15/lab-rat/">2</a>, and 
        <a href="https://as.vanderbilt.edu/nashvillereview/archives/18541">3</a>)
        <br>and Stories (<a href="https://michaelachan.com/Stories/100_The-Giant_2023_self-published.pdf">The Giant,</a>
        <br> <a href="https://michaelachan.com/ArtistsBooks/010_Why-does-the-sun-set_2024">Why Does the Sun Set?,</a>
        <br> <a href="https://michaelachan.com/ArtistsBooks/006_Atrium-Garden_2023">Atrium Garden</a>)
        <br>
        <br>
      </p>

   

  <p style="text-align: center; font-size: x-large">
    See work by year
  </p>
  <div style="text-align: center;">
    <ul class="year-buttons">
      <li *ngFor="let year of years">
        <a [attr.href]="'Years/' + year">
          <button>{{ year }}</button>
        </a>
      </li>
    </ul>
  </div>


  <p>
    <i>We embroider memories onto lace.
      <br> As I mark, I court mistakes
      <br> to be fidelitous to that lace -
      <br> its frames or threadless spaces. </i>
    <br>
  </p>

  <img src="/assets/icons/headshot_michaela-chan.png" style='display:block; width:50%; margin: 0 auto;'/>
  <p>
    Theo Chapman set up this website and <a href="https://patrickphillips.page/">Patrick Phillips</a> is the
    codemaster! Many thanks!
  </p>
</div>
